import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox.jsx";
import { ItemBox } from "@/game-fortnite/components/ItemBox.jsx";
import type { Item } from "@/game-fortnite/models/item.mjs";
import { ItemRarityEnum } from "@/game-fortnite/types/ItemRarity.mjs";
import DataTable from "@/shared/DataTable.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

// Would prefer if this was actually a real enum to use enum numerics
const ItemRarityEntries = Object.entries({
  ...ItemRarityEnum,
  6: "EXOTIC", // Transcendent = Exotic
}).reduce((acc, cur) => {
  acc[cur[0]] = cur[1];
  acc[cur[1]] = cur[0];
  return acc;
}, Object.create(null));

function ItemsUsed({ items: itemsUsed = [] }: { items: Array<Item> }) {
  const { t } = useTranslation();
  const {
    fortnite: { items },
  } = useSnapshot(readState);
  if (!items) return null;
  const rows = itemsUsed
    .map((i) => {
      const item = items[i.key];
      if (!item) return undefined;
      return [
        {
          display: (
            <ItemBox
              id={item.id}
              name={item.displayName}
              rarity={item.rarity.toUpperCase() as Rarity}
              src={`${appURLs.CDN}/fortnite/images/${item.icon}.webp`}
            />
          ),
          value: ItemRarityEntries[item.rarity],
        },
        {
          display: (
            <span className="type-subtitle--bold shade0">
              {item.displayName}
            </span>
          ),
          value: item.displayName,
        },
      ];
    })
    .filter((i) => i);
  const cols = [
    {
      display: t("fortnite:match.rarity", "Rarity"),
    },
    {
      display: t("fortnite:match.itemName", "Item name"),
      isStat: false,
      align: "left",
    },
  ];
  return <DataTable rows={rows} cols={cols} sortCol={0} sortable={false} />;
}

export default ItemsUsed;
